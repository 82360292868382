<template>
  <div>
    <h2 class="h5 add-docker__title mb-6">
      {{ $t('untranslated.docker') }}
      <CyTag
        class="ml-2"
        small
        variant="default">
        {{ $t('development') }}
      </CyTag>
    </h2>

    <p class="mb-6">
      <i18n path="workers.documentationHelp">
        <a
          :href="$docLinks.workers.onPremHelp"
          class="cy-link"
          target="_blank">{{ $t('workers.documentationHelpLink') }}</a>
      </i18n>
    </p>

    <CyWorkersAddForm
      :scheduler-host="schedulerHost"
      :scheduler-port="schedulerPort"
      :scheduler-public-t-s-a-key="schedulerPublicTSAKey"
      @change="(schedulerProperty) => $emit('update', schedulerProperty)"/>
  </div>
</template>

<script>
import CyWorkersAddForm from '@/components/workers/add-form.vue'

export default {
  name: 'CyWorkersAddDockerStepOne',
  components: {
    CyWorkersAddForm,
  },
  props: {
    schedulerHost: {
      type: String,
      default: '',
    },
    schedulerPort: {
      type: String,
      default: '',
    },
    schedulerPublicTSAKey: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.add-docker__title {
  display: flex;
  align-items: center;
  font-weight: $font-weight-bolder;
}
</style>
