export function generateAWSLink ({ cloudWatchRegion, teamId, orgCanonical, workerKey } = {}) {
  return _.some([cloudWatchRegion, teamId, orgCanonical, workerKey], _.isNull)
    ? null
    : `https://${cloudWatchRegion}.console.aws.amazon.com/cloudformation/home?region=${cloudWatchRegion}#/stacks/create/review?templateURL=https://s3-eu-west-1.amazonaws.com/cycloid-cloudformation/external-worker-aws-cf-template.yaml&stackName=cycloid-workers&param_TeamId=${teamId}&param_EnvironmentTag=prod&param_ProjectTag=cycloid-ci-workers&param_CustomerTag=${orgCanonical}&param_WorkerKey=${encodeURIComponent(workerKey)}`
}

export function generateAWSLinkOnPrem ({ cloudWatchRegion, teamId, orgCanonical, workerKey, schedulerHost, schedulerPort, schedulerPublicTSAKey, schedulerAPIAddress } = {}) {
  return _.some([cloudWatchRegion, teamId, orgCanonical, workerKey, schedulerHost, schedulerPort, schedulerPublicTSAKey], _.isNull)
    ? null
    : `https://${cloudWatchRegion}.console.aws.amazon.com/cloudformation/home?region=${cloudWatchRegion}#/stacks/create/review?templateURL=https://s3-eu-west-1.amazonaws.com/cycloid-cloudformation/external-worker-aws-cf-template.yaml&stackName=cycloid-workers&param_TeamId=${teamId}&param_EnvironmentTag=prod&param_ProjectTag=cycloid-ci-workers&param_CustomerTag=${orgCanonical}&param_WorkerKey=${encodeURIComponent(workerKey)}&param_SchedulerApiAddress=${schedulerAPIAddress}&param_TsaPublicKey=${schedulerPublicTSAKey}&param_SchedulerPort=${schedulerPort}&param_SchedulerHost=${schedulerHost}`
}

export function generateGCPCode ({ teamId, orgCanonical, workerKey } = {}) {
  return _.some([teamId, orgCanonical, workerKey], _.isNull)
    ? null
    : `
  export CYCLOID_TEAM="${teamId}"
  export CYCLOID_WORKER_KEY="${workerKey}"
  export CYCLOID_ORG="${orgCanonical}"
  export CYCLOID_WORKER_NAME="cycloid-workers-${orgCanonical}"

  wget -O /tmp/external-worker-gcp-template.yaml.sample https://raw.githubusercontent.com/cycloid-community-catalog/stack-external-worker/master/extra/gcp/external-worker-gcp-template.yaml.sample
  envsubst < /tmp/external-worker-gcp-template.yaml.sample > external-worker-gcp-template.yaml
  gcloud deployment-manager deployments create \${CYCLOID_WORKER_NAME} --config external-worker-gcp-template.yaml
  `
}

export function generateGCPCodeOnPrem ({ teamId, orgCanonical, workerKey, schedulerHost, schedulerPort, schedulerPublicTSAKey, schedulerAPIAddress } = {}) {
  return _.some([teamId, orgCanonical, workerKey], _.isNull)
    ? null
    : `
  export CYCLOID_TEAM="${teamId}"
  export CYCLOID_WORKER_KEY="${workerKey}"
  export CYCLOID_ORG="${orgCanonical}"
  export CYCLOID_WORKER_NAME="cycloid-workers-${orgCanonical}"
  export CYCLOID_SCHEDULER_PORT="${schedulerPort}"
  export CYCLOID_SCHEDULER_API_ADDRESS="${schedulerAPIAddress}"
  export CYCLOID_SCHEDULER_HOST="${schedulerHost}"
  export CYCLOID_SCHEDULER_PUB_KEY="${schedulerPublicTSAKey}"

  wget -O /tmp/external-worker-gcp-template.yaml.sample https://raw.githubusercontent.com/cycloid-community-catalog/stack-external-worker/master/extra/gcp/external-onprem-worker-gcp-template.yaml.sample
  envsubst < /tmp/external-worker-gcp-template.yaml.sample > external-worker-gcp-template.yaml
  gcloud deployment-manager deployments create \${CYCLOID_WORKER_NAME} --config external-worker-gcp-template.yaml
  `
}

export function generateBareMetalCode ({ teamId, workerKey } = {}) {
  return _.some([teamId, workerKey], _.isNull)
    ? null
    : `
  export TEAM_ID="${teamId}"
  export WORKER_KEY="${workerKey}"
  export VAR_LIB_DEVICE="nodevice"
  export CLOUD_PROVIDER="baremetal"

  export LOG_FILE="/var/log/user-data.log"
  exec &> >(tee -a \${LOG_FILE})

  curl -sSL "https://raw.githubusercontent.com/cycloid-community-catalog/stack-external-worker/master/extra/startup.sh?\${RANDOM}" | bash
  `
}

export function generateBareMetalCodeOnPrem ({ teamId, workerKey, schedulerHost, schedulerPort, schedulerAPIAddress, schedulerPublicTSAKey } = {}) {
  return _.some([teamId, workerKey], _.isNull)
    ? null
    : `
  export TEAM_ID="${teamId}"
  export WORKER_KEY="${workerKey}"
  export VAR_LIB_DEVICE="nodevice"
  export CLOUD_PROVIDER="baremetal"
  export SCHEDULER_PORT="${schedulerPort}"
  export SCHEDULER_API_ADDRESS="${schedulerAPIAddress}"
  export SCHEDULER_HOST="${schedulerHost}"
  export TSA_PUBLIC_KEY="${schedulerPublicTSAKey}"

  export LOG_FILE="/var/log/user-data.log"
  exec &> >(tee -a \${LOG_FILE})

  curl -sSL "https://raw.githubusercontent.com/cycloid-community-catalog/stack-external-worker/master/extra/startup.sh?\${RANDOM}" | bash
  `
}

export function generateDockerCode ({ teamId, workerKey } = {}) {
  return _.some([teamId, workerKey], _.isNull)
    ? null
    : `
  export CYCLOID_WORKER_TEAM="${teamId}"
  export CYCLOID_WORKER_KEY="${workerKey}"

  docker run -it --rm --privileged --name cycloid-worker -e TEAM_ID="\${CYCLOID_WORKER_TEAM}" -e WORKER_KEY="\${CYCLOID_WORKER_KEY}" cycloid/local-worker
  `
}

export function generateDockerCodeOnPrem ({ teamId, workerKey, schedulerHost, schedulerPort, schedulerPublicTSAKey } = {}) {
  return _.some([teamId, workerKey], _.isNull)
    ? null
    : `
  export CYCLOID_WORKER_TEAM="${teamId}"
  export CYCLOID_WORKER_KEY="${workerKey}"
  export CYCLOID_SCHEDULER_PORT="${schedulerPort}"
  export CYCLOID_SCHEDULER_HOST="${schedulerHost}"
  export CYCLOID_SCHEDULER_PUB_KEY="${schedulerPublicTSAKey}"

  docker run -it --rm --privileged --name cycloid-worker -e TEAM_ID="\${CYCLOID_WORKER_TEAM}" -e WORKER_KEY="\${CYCLOID_WORKER_KEY}" -e SCHEDULER_HOST="\${CYCLOID_SCHEDULER_HOST}" -e SCHEDULER_PORT="\${CYCLOID_SCHEDULER_PORT}" -e TSA_PUBLIC_KEY="\${CYCLOID_SCHEDULER_PUB_KEY}" cycloid/local-worker
  `
}
