<template>
  <div class="add-form">
    <v-text-field
      class="required-field"
      required
      :value="schedulerHost"
      persistent-hint
      :hint="$t('untranslated.schedulerHostHint')"
      :label="$t('untranslated.schedulerHost')"
      @input="$emit('change', { schedulerHost: $event })"/>

    <v-text-field
      class="required-field"
      required
      :value="schedulerPort"
      persistent-hint
      :hint="$t('untranslated.schedulerPortHint')"
      :label="$t('untranslated.schedulerPort')"
      @input="$emit('change', { schedulerPort: $event })"/>

    <v-text-field
      class="required-field"
      required
      :value="schedulerPublicTSAKey"
      persistent-hint
      :hint="$t('untranslated.schedulerPublicTSAKeyHint')"
      :label="$t('untranslated.schedulerPublicTSAKey')"
      @input="$emit('change', { schedulerPublicTSAKey: $event })"/>
  </div>
</template>

<script>
export default {
  name: 'CyWorkersAddForm',
  props: {
    schedulerHost: {
      type: String,
      default: '',
    },
    schedulerPort: {
      type: String,
      default: '',
    },
    schedulerPublicTSAKey: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
  .add-form > * + * {
    margin-top: 1rem;
  }
</style>
