function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('CyModal',{attrs:{"small":"","action-btn-hidden":"","header-title":_vm.$t('addWorker')}},[_c('v-stepper',{staticClass:"px-0",attrs:{"data-cy":"stepper","elevation":"0"},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-header',{staticClass:"elevation-0 px-0"},[_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > _vm.$static.FIRST_STEP,"step":_vm.$static.FIRST_STEP}},[_vm._v(" "+_vm._s(_vm.$t('forms.type'))+" ")]),_c('v-divider'),(_vm.isThreeStepProvider)?[_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > _vm.$static.FIRST_STEP + 1,"step":_vm.$static.FIRST_STEP + 1}},[_vm._v(" "+_vm._s(_vm.$t('configuration'))+" ")]),_c('v-divider')]:_vm._e(),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > _vm.finalStep,"step":_vm.finalStep}},[_vm._v(" "+_vm._s(_vm.$t('deploy'))+" ")])],2),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"px-0",attrs:{"step":_vm.$static.FIRST_STEP}},[_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t('select'))+" ")]),_vm._l((_vm.$static.configGroups),function(group,groupIndex){return _c('div',{key:("configuration-group-" + groupIndex),staticClass:"selection"},[_c('div',{staticClass:"selection__title"},[_vm._v(" "+_vm._s(group.text)+" ")]),_vm._l((group.items),function(provider,providerIndex){return _c('div',{key:("configuration-group-" + groupIndex + "-item-" + providerIndex),class:['selection__item', {
              'selection__item--selected': _vm.selectedProvider === provider,
            }],attrs:{"disabled":!_vm.canSelect(provider)},on:{"click":function($event){return _vm.selectItem(provider)}}},[_c('v-icon',{staticClass:"selection__icon mr-4"},[_vm._v(" "+_vm._s(_vm.selectedProvider === provider ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank')+" ")]),(_vm.providersConfig[provider].hasLogo)?_c('CyIconCredential',{staticClass:"selection__img",attrs:{"size":"20","type":provider}}):_vm._e(),_c('CyTooltip',{attrs:{"right":"","disabled":_vm.canSelect(provider),"content-class":"missing-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"selection__text d-flex"},on),[_vm._v(" "+_vm._s(_vm.providersConfig[provider].text)+" "),_c('CyTag',{directives:[{name:"show",rawName:"v-show",value:(_vm.providersConfig[provider].hasDevelopmentTag),expression:"providersConfig[provider].hasDevelopmentTag"}],staticClass:"ml-2",attrs:{"small":"","variant":"default"}},[_vm._v(" "+_vm._s(_vm.$t('development'))+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$tc('missingCredential', _vm.getMissingCredentials(provider).length))+" "),_vm._l((_vm.getMissingCredentials(provider)),function(key){return _c('code',{key:key,domProps:{"innerHTML":_vm._s(key)}})})],2)],1)})],2)})],2),_c('v-stepper-content',{attrs:{"step":_vm.$static.FIRST_STEP + 1}},[_vm._l((_vm._.entries(_vm.providersConfig)),function(ref){
            var providerKey = ref[0];
            var ref_1 = ref[1];
            var component = ref_1.component;
            var rest = objectWithoutProperties( ref_1, ["component"] );
            var providerProps = rest;
return [(_vm.selectedProvider === providerKey)?_c(component,_vm._b({key:component.name,tag:"component",staticClass:"mt-4",on:{"update":_vm.setProperties}},'component',providerProps,false)):_vm._e()]})],2),(_vm.isThreeStepProvider)?_c('v-stepper-content',{attrs:{"step":_vm.finalStep}},[_vm._l((_vm._.entries(_vm.providersConfig)),function(ref){
            var providerKey = ref[0];
            var ref_1 = ref[1];
            var componentStepTwo = ref_1.componentStepTwo;
            var rest = objectWithoutProperties( ref_1, ["componentStepTwo"] );
            var providerProps = rest;
return [(_vm.selectedProvider === providerKey)?_c(componentStepTwo,_vm._b({key:componentStepTwo.name,tag:"component",staticClass:"mt-4",on:{"update":_vm.setProperties}},'component',providerProps,false)):_vm._e()]})],2):_vm._e()],1)],1),_c('template',{slot:"modal-buttons"},[(_vm.currentStep > _vm.$static.FIRST_STEP)?_c('CyButton',{attrs:{"icon":"mdi-chevron-left","theme":"grey","variant":"tertiary"},on:{"click":function($event){_vm.currentStep--}}},[_vm._v(" "+_vm._s(_vm.$t('forms.back'))+" ")]):_vm._e(),_c('v-spacer'),_c('CyButton',{attrs:{"icon":"close","theme":"grey","variant":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('forms.btnClose'))+" ")]),(_vm.currentStep < _vm.finalStep)?_c('CyButton',{attrs:{"icon":"mdi-chevron-right","theme":"secondary","disabled":!_vm.selectedProvider || _vm.isOnStepTwoAndNoDataIsProvided},on:{"click":function($event){_vm.currentStep++}}},[_vm._v(" "+_vm._s(_vm.$t('forms.btnContinue'))+" ")]):(_vm.hasAction(_vm.selectedProvider))?_c('CyButton',{attrs:{"disabled":!_vm.canPerformAction(_vm.selectedProvider),"icon":"check"},on:{"click":_vm.providersConfig[_vm.selectedProvider].action}},[_vm._v(" "+_vm._s(_vm.providersConfig[_vm.selectedProvider].actionText || _vm.$t('forms.btnConfirm'))+" ")]):_vm._e()],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }