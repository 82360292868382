<template>
  <div class="summary">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'CyWorkersSummary',
}
</script>

<style lang="scss" scoped>
.summary {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  background: cy-get-color("grey", "light-3");
}
</style>
