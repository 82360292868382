<template>
  <v-card class="no-workers-card">
    <h5 class="no-workers-card__title">
      {{ $t('notConnected') }}
    </h5>
    <p class="no-workers-card__description">
      {{ $t('description') }}
      <a
        :href="$docLinks.workers.overview"
        class="cy-link"
        target="_blank">
        {{ $t('learnMore') }}
      </a>
    </p>
    <CyButton
      v-has-rights-to="'GetWorkers'"
      icon="add"
      @click="$emit('add')">
      {{ $t('addWorker') }}
    </CyButton>
  </v-card>
</template>

<script>
export default {
  name: 'CyWorkersEmpty',
  i18n: {
    messages: {
      en: {
        addWorker: 'Add worker',
        description: 'Workers are used to execute pipeline jobs and perform resource checks. In other words having at least one worker is mandatory for your pipelines to work.',
        learnMore: 'Learn about workers.',
        notConnected: `It looks like you don't have any connected workers`,
      },
      es: {
        addWorker: 'Agregar worker',
        description: 'Los workers se utilizan para ejecutar trabajos de pipelines y realizar controles de recursos. En otras palabras, tener al menos un worker es obligatorio para que sus pipelines funcionen.',
        learnMore: 'Aprender sobre los workers.',
        notConnected: 'Parece que no tiene ningún worker conectado.',
      },
      fr: {
        addWorker: 'Ajouter un worker',
        description: `Les workers sont utilisés pour exécuter les jobs de vos pipelines et contrôler vos ressources. En d'autres termes, au moins un worker est obligatoire pour que vos pipelines fonctionnent.`,
        learnMore: 'En savoir plus sur les workers.',
        notConnected: `Il semble que vous n'ayez pas de worker connecté`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.no-workers-card {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;

  &__title {
    margin-bottom: 8px;
    color: cy-get-color("primary", "light-2");
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: $line-height-heading;
    text-align: center;
  }

  &__description {
    width: 50%;
    max-width: 400px;
    margin-bottom: 32px;
    color: cy-get-color("primary", "light-3");
    font-size: $font-size-default;
    text-align: center;
  }
}
</style>
