<template>
  <h2 class="h5 workers-title">
    <CyIconCredential
      class="workers-title__icon"
      size="30"
      :type="iconType"/>
    <div class="workers-title__text">
      <slot/>
    </div>
  </h2>
</template>

<script>
export default {
  name: 'CyWorkersAddTitle',
  props: {
    iconType: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.workers-title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-weight: $font-weight-bolder;
  line-height: 1;

  &__text {
    margin-left: 0.25rem;
  }
}
</style>
