<template>
  <div>
    <CyWorkersAddTitle icon-type="aws">
      {{ $t('untranslated.aws.name') }}
    </CyWorkersAddTitle>

    <p>{{ $t('p1') }}</p>

    <p>
      <i18n path="workers.documentationHelp">
        <a
          :href="$docLinks.workers.onPremHelp"
          class="cy-link"
          target="_blank">{{ $t('workers.documentationHelpLink') }}</a>
      </i18n>
    </p>

    <CyWorkersAddForm
      :scheduler-host="schedulerHost"
      :scheduler-port="schedulerPort"
      :scheduler-public-t-s-a-key="schedulerPublicTSAKey"
      @change="(schedulerProperty) => $emit('update', schedulerProperty)"/>
  </div>
</template>

<script>
import CyWorkersAddForm from '@/components/workers/add-form.vue'
import CyWorkersAddTitle from '@/components/workers/add-title.vue'

export default {
  name: 'CyWorkersAddAwsStepOne',
  components: {
    CyWorkersAddForm,
    CyWorkersAddTitle,
  },
  props: {
    schedulerHost: {
      type: String,
      default: '',
    },
    schedulerPort: {
      type: String,
      default: '',
    },
    schedulerPublicTSAKey: {
      type: String,
      default: '',
    },
  },
  i18n: {
    messages: {
      en: {
        p1: 'Workers are deployed on AWS through AWS CloudFormation.',
      },
      es: {
        p1: 'Los workers se despliegan en AWS a través de AWS CloudFormation.',
      },
      fr: {
        p1: 'Les workers sont déployés sur AWS via AWS Cloudformation.',
      },
    },
  },
}
</script>
