<template>
  <div>
    <CyWorkersAddTitle icon-type="gcp">
      {{ $t('untranslated.gcp.name') }}
    </CyWorkersAddTitle>
    <div>
      <p>
        <i18n path="workers.documentationHelp">
          <a
            :href="$docLinks.workers.onPremHelp"
            class="cy-link"
            target="_blank">{{ $t('workers.documentationHelpLink') }}</a>
        </i18n>
      </p>

      <CyWorkersAddForm
        :scheduler-host="schedulerHost"
        :scheduler-port="schedulerPort"
        :scheduler-public-t-s-a-key="schedulerPublicTSAKey"
        @change="(schedulerProperty) => $emit('update', schedulerProperty)"/>
    </div>
  </div>
</template>

<script>
import CyWorkersAddForm from '@/components/workers/add-form.vue'
import CyWorkersAddTitle from '@/components/workers/add-title.vue'

export default {
  name: 'CyWorkersAddGcpStepOne',
  components: {
    CyWorkersAddForm,
    CyWorkersAddTitle,
  },
  props: {
    schedulerHost: {
      type: String,
      default: '',
    },
    schedulerPort: {
      type: String,
      default: '',
    },
    schedulerPublicTSAKey: {
      type: String,
      default: '',
    },
  },
}
</script>
