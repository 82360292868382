<template>
  <div>
    <CyWorkersAddTitle icon-type="flexibleengine">
      {{ $t('untranslated.flexeng') }}
    </CyWorkersAddTitle>
    <ul>
      <li>
        <i18n path="downloadFile">
          <a
            :href="$docLinks.workers.flexengZip"
            class="cy-link"
            target="_blank">{{ $t('linkFlexengZip') }}</a>
        </i18n>
      </li>
      <li v-html="$sanitizeHtml($t('uploadFile' ))"/>
      <li>{{ $t('copyPaste') }}</li>
      <li>
        <i18n path="checkDoc">
          <a
            :href="$docLinks.workers.flexeng"
            class="cy-link"
            target="_blank">{{ $t('linkText') }}</a>
        </i18n>
      </li>
      <template v-if="isOnPrem">
        <li>{{ $t('onPremConfig') }}</li>
        <ul class="add-azure__list--nested">
          <li>{{ $t('untranslated.schedulerHost') }}</li>
          <li>{{ $t('untranslated.schedulerPublicTSAKey') }}</li>
          <li>{{ $t('untranslated.schedulerPort') }}</li>
        </ul>
        <li>{{ $t('help') }}</li>
      </template>
    </ul>
    <CyWorkersSummary>
      <CyWorkersSummaryItem
        :value="vaultRoleId"
        :text="$t('untranslated.vaultRoleId')"
        with-copy/>
      <CyWorkersSummaryItem
        :value="vaultSecretId"
        :text="$t('untranslated.vaultSecretId')"
        with-copy/>
      <CyWorkersSummaryItem
        :value="teamId"
        :text="$t('untranslated.teamId')"
        with-copy/>

      <CyWorkersSummaryItem
        v-if="isOnPrem"
        :value="schedulerAPIAddress"
        :text="$t('untranslated.schedulerAPIAddress')"
        with-copy/>
    </CyWorkersSummary>

    <p class="mt-6 mb-0">
      <i18n path="workers.troubleshoot">
        <a
          :href="$docLinks.workers.troubleshooting"
          class="cy-link"
          target="_blank">{{ $t('workers.troubleshootLink') }}</a>
      </i18n>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyWorkersAddTitle from '@/components/workers/add-title.vue'
import CyWorkersSummaryItem from '@/components/workers/summary-item.vue'
import CyWorkersSummary from '@/components/workers/summary.vue'

export default {
  name: 'CyWorkersAddFlexeng',
  components: {
    CyWorkersSummary,
    CyWorkersSummaryItem,
    CyWorkersAddTitle,
  },
  props: {
    vaultRoleId: {
      type: String,
      required: true,
    },
    vaultSecretId: {
      type: String,
      required: true,
    },
    teamId: {
      type: String,
      default: '',
    },
    schedulerAPIAddress: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('organization/licence', [
      'isOnPrem',
    ]),
  },
  i18n: {
    messages: {
      en: {
        checkDoc: 'Check the {0} to help you complete the configuration, then save it.',
        copyPaste: 'Copy paste the values below in the corresponding fields',
        deployment: 'Deployment',
        downloadFile: 'Download the {0} resource template. Then open your @:untranslated.flexeng console, go in the Resource Template Service section and create a new stack.',
        linkFlexengZip: 'flexible-engine.zip',
        linkText: '@:untranslated.flexeng workers documentation',
        uploadFile: 'Upload the previously downloaded <span class="doc-tag">flexible-engine.zip</span> in the template source, select <span class="doc-tag">main.yml</span> as the main file then click Next.',
        onPremConfig: `In addition you'll need to know these Cycloid server configuration values:`,
        help: 'If you need further help, contact your system administrator.',
      },
      es: {
        checkDoc: 'Verifique la {0} para ayudarlo a completar la configuración, luego guardarla.',
        copyPaste: 'Copiar Pegar los valores a continuación en los campos correspondientes',
        deployment: 'Despliegue',
        downloadFile: 'Descargue la plantilla de recursos {0}. Luego, abra su consola de @:untranslated.flexeng, vaya a la sección Resource Template Service y cree un nuevo stack.',
        linkFlexengZip: 'flexible-engine.zip',
        linkText: 'documentación de los workers de @:untranslated.flexeng',
        uploadFile: 'Suba el <span class="doc-tag">flexible-engine.zip</span> previamente descargado en la plantilla, seleccione <span class="doc-tag">main.yml</span> como el archivo principal y luego haga clic en Siguiente.',
        onPremConfig: `Además, necesitará conocer estos valores de configuración del servidor Cycloid:`,
        help: 'Si necesita más ayuda, póngase en contacto con el administrador del sistema.',
      },
      fr: {
        checkDoc: 'Vérifiez la {0} pour vous aider à compléter la configuration, puis enregistrez-la.',
        copyPaste: 'Copier coller les valeurs ci-dessous dans les champs correspondants',
        deployment: 'Déploiement',
        downloadFile: 'Téléchargez le modèle de ressource {0}. Ouvrez ensuite votre console @:untranslated.flexeng, allez dans la section Resource Template Service et créez une nouvelle stack.',
        linkFlexengZip: 'flexible-engine.zip',
        linkText: 'documentation des workers @:untranslated.flexeng',
        uploadFile: 'Téléversez le fichier <span class="doc-tag">flexible-engine.zip</span> précédemment téléchargé dans la source de modèle, sélectionnez <span class="doc-tag">main.yml</span> en tant que fichier principal, puis cliquez sur Suivant.',
        onPremConfig: 'De plus, vous devrez connaitre ces valeurs de configuration de serveur Cycloid:',
        help: `Si vous avez besoin d'aide supplémentaire, contactez votre administrateur système.`,
      },
    },
  },
}
</script>
